import { Fragment } from 'react';
import {
    DateField,
    TextField,
    TextInput,
    Filter,
    SelectInput,
    required,
    BulkExportButton,
    BooleanField,
} from 'react-admin';
import { List, ListActions } from '@react-admin/ra-enterprise';
import { CustomBreadcrumb } from './CustomBreadcrumb';
import CustomPagination from './CustomPagination';
import { makeStyles } from '@material-ui/core/styles';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import RefUserLink from './RefUserLink';
import RefUidLink from './RefUidLink';
import SpentDate from './SpentDate';


function idRoleAllow(resource_name, action_name) {
    let return_value = 'no';
    let login_role = localStorage.getItem('role');
    if (login_role == 'admin') {
        return 'ok';
    }
    let allpermission = JSON.parse(localStorage.getItem("roles"));
    let roledata = [];
    if (login_role == 'accountant') {
        roledata = allpermission.accountant;
    }
    if (login_role == 'auditor') {
        roledata = allpermission.auditor;
    }
    if (login_role == 'editor') {
        roledata = allpermission.editor;
    }
    roledata.map((role_row, index) => {
        if ((role_row.resource == resource_name) && (role_row.action.indexOf(action_name) > -1)) {
            console.log('operation ok');
            return_value = 'ok';
        }
    });
    return return_value;
}
const PostBulkActionButtons = ({ basePath }) => (
    <Fragment>
        {(idRoleAllow('orders', 'export') == 'ok') ?
            <BulkExportButton /> : ''}
        {/* {(idRoleAllow('orders', 'delete') == 'ok') ?
            <BulkDeleteButton basePath={basePath} /> : ''} */}
    </Fragment>
)

const OrderListActions = (props) => (
    <ListActions  {...props} breadcrumb={<CustomBreadcrumb variant="actions" />} exporter={(idRoleAllow('orders', 'export') == 'ok') ? true : false} />
)

const OrdersFilter = (props) => (
    <Filter {...props}>
        <TextInput label="User Id" source="referee_id" defaultValue="" />
        <TextInput label="User name" source="referee_name" defaultValue="" />
        {/* <DateInput label="Created since" source="created_at" defaultValue="" /> */}
        {/* <DateInput label="Created before" source="updated_at" defaultValue="" /> */}
    </Filter>
);


const RefereeForm = props => (
    <RowForm {...props}>
        <TextField source="referee_id" label="Referee Id" />
        <TextField source="referee_name" label="Referee name" />
        <TextField source="total_orders" label="Orders" />
        <TextField source="total_spent" label="Spent" />
        <TextField source="total_profit" label="Profits" />
        <TextField source="spent_at_alter" label="Spent at"/>
        <DateField source="created_at" label="Created at" showTime/>
        <SelectInput source="status" choices={status_choices} label="Status" validate={required()} />
    </RowForm>
);

const status_choices = [
    { id: true, name: 'Active' },
    { id: false, name: 'Inactive' }];

const newStyle = makeStyles((theme) => ({
    tdcolor: { color: (theme.palette.type === 'light') ? '#4f3cc9' : '#90caf9' }
}));

const ReferralList = (props) => {
    const classes = newStyle();
    return (
        <List
            {...props}
            sort={{ field: 'user_id', order: 'ASC' }}
            actions={<OrderListActions />}
            filters={<OrdersFilter />}
            title={'Referee Info'}
            bulkActionButtons={<PostBulkActionButtons />}
            pagination={<CustomPagination />}
        >
            <EditableDatagrid
                className="lastOrdersColumn"
                rowClick="edit"
                createForm={<RefereeForm />}
                editForm={<RefereeForm />}
            >
                <RefUidLink source="referee_id" label="Referee Id" className={classes.tdcolor} />
                <RefUserLink source="referee_name" label="Referee name" />
                <TextField source="total_orders" label="Orders" />
                <TextField source="total_spent" label="Spent" />
                <TextField source="total_profit" label="Profits" />
                <SpentDate source="spent_at" showTime label="Spent at" />
                <DateField source="created_at" showTime label="Created at" />
                <BooleanField source="status" label="Status" />
            </EditableDatagrid>
        </List>
    )
};
export default ReferralList;